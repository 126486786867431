<template>
  <div class="recharge">
    <!-- 导航栏 -->
    <van-nav-bar :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('home.deposit') }}</span>
      </template>
      <template #right>
        <button @click="$router.push('/user/pay/record')"
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }">
          {{ $t('login.history') }}
        </button>
        <button @click="handlerService" :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }">
          <img src="../../assets/images/user_pay/letter35.png" alt="" srcset="" class="pic" />
        </button>
      </template>
    </van-nav-bar>

    <!-- language-ch 充值账户：₫ -->
    <van-field center v-model="account" :label="$t('recharge.membershipAccount')"
      :placeholder="$t('recharge.pleaseMembershipAccount')" />
    <!-- language-ch mặc định -->
    <!-- 允许输入数字，调起带符号的纯数字键盘 -->
    <van-field center class="number" v-model="number" type="number"
      :label="`${$t('recharge.amount')}${verConfig.moneyType}`" :placeholder="$t('home.pleaseEnterAmount')" />

    <van-grid :gutter="10" class="grid">
      <van-grid-item class="grid_item" v-for=" value  in  countList " :key="value" @click="addClass(value)">
        <div slot="text" :class="{ active: number === value }">
          {{ value }}
        </div>
      </van-grid-item>
    </van-grid>

    <van-cell title="tủ">
      <!-- language-ch 选择充值方式 -->
      <span slot="title" class="list-title-text"> {{ $t('recharge.pleaseSelectDepositMethod') }}</span>
      <!-- language-ch 充值教程 -->
      <!-- <span slot="default" class="list-title-rtext" @click="showTutorial = true"
        >Top-up tutorial</span
      > -->
    </van-cell>

    <div class="tutorial" v-show="showTutorial">
      <img :src="require('@/assets/images/user_pay/step/' + addCount + '.jpg')" @click="changeImg" />
    </div>

    <!-- 标签页 支付方式-->
    <van-tabs v-model="active" swipeable class="tabs" line-height="2px" line-width="15px">
      <van-tab v-for=" item  in  tabPages " :key="item.type">
        <span slot="title">{{ item.title }}</span>
        <div v-if="item.lists.length > 0">
          <div class="mode" v-for=" value  in  item.lists " :key="value.id" @click="paySubmit(value)">
            <img v-if="item.type !== 'idpay'" class="step-image"
              :src="require(`@/assets/images/user_pay/${item.type}.png`)" />
            <div class="step">
              <h5>{{ value.title }}</h5>
              <p>{{ value.desc }}</p>
            </div>
            <img v-if="value.letter == 1" class="tabbar-letter" :src="require('@/assets/images/user_pay/letter.png')" />
          </div>
        </div>
        <div v-else class="tips">
          {{ $t('recharge.thereNoSuchMethod') }}
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import request, { isLinkGame } from '@/utils/request.js'
import { userInfo } from '@/api/use.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      countList: isLinkGame
        ? ['100', '300', '500', '1000', '2000', '5000']
        : ['200', '500', '2000', '5000', '10000', '50000'],
      account: '',
      number: 100,
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: ''
    }
  },
  mounted() { },
  created() {
    this.getLists()
    this.getInfo()
    this.getService()
  },
  methods: {
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'pay/lists'
      })
      if (ret === 1) {
        this.tabPages = data
      }
    },

    async getInfo() {
      const {
        data: { data, ret }
      } = await userInfo()
      if (ret === 1) this.account = data.userphone
    },

    async getService() {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0
        }
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
      }
    },
    // 教程
    changeImg() {
      this.count += 1
      if (this.count === 6) {
        this.showTutorial = false
        this.count = 1
      }
    },

    handlerService() {
      //   this.$toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL
    },

    async paySubmit(item) {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'pay/submit',
        params: {
          id: item.id,
          money: this.number,
          account: this.account,
          isbefore: 1
        }
      })
      if (ret === 2) {
        window.location.href = data.url
      } else if (ret === 3) {
        this.$router.push({
          path: '/user/pay_bank',
          name: 'payBank',
          query: {
            payInfo: JSON.stringify(data),
            step: item.step,
            id: item.id,
            account: this.account
          }
        })
      } else {
        this.$toast('System exception, please try again later')
      }
    }
  },
  computed: {
    addCount() {
      return this.count
    }
  }
}
</script>

<style lang="less" scoped>
.recharge {
  position: relative;
  font-family: 'PingFang-Regular';
  height: 100%;

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      // color: #fff;
    }

    button {
      background-color: #ff0000;
      border: none;
      color: #fff;
      font-size: 20px;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-field {
    margin: 12px 0;

    /deep/ .van-field__label {
      width: 255px !important;
    }

    /deep/ .van-field__body {
      input {
        text-align: center;
      }
    }
  }

  .number {
    /deep/ .van-field__body {
      input {
        text-align: center;
        color: #ff0000;
      }
    }
  }

  .grid {
    margin: 15px 0;
    display: flex;
    justify-content: space-around;

    .grid_item {
      flex-basis: 30% !important;

      /deep/ .van-grid-item__content {
        padding: 0;

        div {
          width: 100%;
          height: 82px;
          font-size: 26px;
          line-height: 90px;
          text-align: center;
          color: #dc1323;
          background-color: #ffffff;
        }

        .active {
          color: #ffffff;
          background-color: #dc1323;
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }

  .list-title-text {
    font-size: 26px;
  }

  .list-title-rtext {
    color: #a87cf3;
    font-size: 26px;
    text-decoration: underline;
  }

  .tabs {
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: 15px;
      padding: 15px;
      background-color: #fff;
    }

    .step {
      font-size: 24px;

      h5 {
        margin: 0 0 15px 0;
      }
    }

    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: 65px;
      height: 65px;
    }

    .step-image {
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }

    .tips {
      text-align: center;
      margin-top: 90px;
      font-size: 20px;
    }
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .pic {
    width: 35px;
    height: 35px;
  }
}
</style>
