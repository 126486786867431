/*
 * @Author: your name
 * @Date: 2022-03-04 10:52:57
 * @LastEditTime: 2022-03-29 11:59:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vi\src\utils\request.js
 */
import axios from 'axios'
import store from '@/store/index.js'
import verConfig from "@/utils/ver"
import Cookies from 'js-cookie';
import router from '@/router';
export const baseUrl = verConfig.url;

export const isLinkGame = verConfig.isLinkGame || false; // true->wing; false->Shopee+1

// export const baseURL = isLinkGame
//   ? 'http://147.139.40.215:9091/api/'
//   // : 'http://147.139.40.32:9091/api/'
//   : 'https://xanh7.com/api/'
export const baseURL = verConfig.url;

const proxy =
  process.env.NODE_ENV === 'development' ? 'api' : `${baseUrl}api`
export const chatImg = verConfig.url;

const request = axios.create({
  // baseURL: 'https://wing85.com/api/'
  // baseURL: 'https://shrimpskins.shop/api/'
  baseURL: proxy
})
request.interceptors.request.use(
  config => {
    config.headers = {
      lang: Cookies.get('language'),
      token: store.state.user ? store.state.user : null
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
request.interceptors.response.use(
  (response) => {
    const { ret, msg } = response.data
    if (ret === -1) {
      // Toast(msg)
      setTimeout(() => {
        router.replace("login")
      }, 1000)
      localStorage.removeItem("TOUTIAO_USER")
    }
    return response
  }
)

export const $get = (url, params) => request({
  url,
  method: 'get',
  params: (() => {
    if (!params) {
      params = new Object;
    }
    // params.lang = verConfig.lan || "en";
    return params
  })(),
  headers: {
    token: localStorage.getItem('token')
  }
})
export const $post = (url, data) => request({
  url,
  method: 'post',
  data,
  headers: {
    token: localStorage.getItem('token')
  }
})
export const $upload = ({ url, data }) => {
  return request({
    url,
    method: 'post',
    data,
    headers: {
      token: localStorage.getItem('token'),
      "Content-Type": "multipart/form-data"
    }
  })
}
export default request
