export default {
  "name": "Pangalan",
  "tel": "Telepono",
  "save": "I-save",
  "confirm": "Kumpirmahin",
  "cancel": "Kanselahin",
  "delete": "Tanggalin",
  "complete": "Kumpleto",
  "loading": "Naglo-load...",
  "telEmpty": "Pakisagot ang telepono",
  "nameEmpty": "Pakisagot ang pangalan",
  "nameInvalid": "Maling porma ng pangalan",
  "confirmDelete": "Sigurado ka bang gusto mong tanggalin?",
  "telInvalid": "Maling porma ng numero ng telepono",
  "vanCalendar": {
    "end": "Wakas",
    "start": "Simula",
    "title": "Kalendaryo",
    "startEnd": "Simula/Wakas",
    "weekdays": ["Ling", "Mar", "Miy", "Huw", "Biy", "Sab", "Abo"],
    "monthTitle": (year, month) => `${year}/${month}`,
    "rangePrompt": (maxRange) => `Pumili ng hindi hihigit sa ${maxRange} (na) araw`,
  },
  "vanCascader": { "select": "Pumili" },
  "vanContactCard": { "addText": "Magdagdag ng impormasyon ng contact" },
  "vanContactList": { "addText": "Magdagdag ng bagong contact" },
  "vanPagination": { "prev": "Nakaraan", "next": "Susunod" },
  "vanPullRefresh": {
    "pulling": "I-drag pababa upang mag-refresh...",
    "loosing": "Bitawan upang mag-refresh..."
  },
  "vanSubmitBar": { "label": "Kabuuan：" },
  "vanCoupon": {
    "unlimited": "Walang limitasyon",
    "discount": (discount) => `${discount * 10}% off`,
    "condition": (condition) => `Hindi bababa sa ${condition}`,
  },
  "vanCouponCell": {
    "title": "Kupon",
    "tips": "Walang mga kupon",
    "count": (count) => `Mayroon kang ${count} na mga k"agreement": {
    "loadingProtocol": "Naglo-load ng protocol...",
    "termsOfUse": "Mga Tuntunin ng Paggamit"
  },
  "bank": {
    "DepositAmount": "Halaga ng Deposito",
    "RechargeAmountDetail": "Kailangan mo ng {time} oras para {busines} {money}. Pakisagot ang seksyon ng deposito",
    "accountHolderName": "Pangalan ng May-ari ng Account:",
    "accountNumber": "Numero ng Account:",
    "actualRechargeAmount": "Aktwal na Halaga ng Deposito",
    "bankCardBound": "Nakataling Bank Card",
    "bankName": "Pangalan ng Bangko:",
    "contactCustomer": "Makipag-ugnayan sa Customer",
    "depositFormSentSuccessfully": "Matagumpay na Pagsusumite ng Deposit",
    "enterYourRealName": "Pakilagay ang iyong tunay na pangalan",
    "failure": "Kabiguan",
    "keepTheSame": "Panatilihin ang Pareho",
    "myBankCard": "Aking Bank Card",
    "noteWhenDepositingMoney": "Tandaan Kapag Nagdedeposito ng Pera",
    "pleaseFillBankName": "Pakilagay ang pangalan ng iyong bangko",
    "pleaseYourAccountNumber": "Pakilagay ang iyong numero ng account",
    "submitDepositRequest": "Isumite ang Aplikasyon ng Deposito",
    "submitOrder": "Isumite ang Order",
    "text": "Tandaan: Mahalaga ang impormasyon ng bank card. Huwag itong baguhin matapos idagdag! Salamat."
  },
  "blindBox": {
    "congratulationsCustomer": "Binabati kita sa customer",
    "drawNow": "Mag-draw Ngayon",
    "hit": "Nanalo ng Premyo",
    "lotteryLaw": "Batas ng Lottery",
    "myScore": "Aking Puntos",
    "noMore": "Wala nang Iba Pa",
    "numberDrawsTimes": "Bilang ng Draws: {gift_times} beses",
    "obtainUserInformation": "Hindi makuha ang impormasyon ng user:",
    "onlineServices": "Online na Serbisyo",
    "requiredPointsPoints": "Kinakailangang Puntos: {point} puntos",
    "rewardPointsRedemptionList": "Listahan ng Pag-redeem ng Puntos na Gantimpala",
    "winningHistory": "Kasaysayan ng Pagkapanalo",
    "winningList": "Nanalo siya ng premyo",
    "winningRecord": "Tala ng Pagkapanalo",
    "withdrawalProfile": "Tala ng Pag-withdraw"
  },
  "chatroom": {
    "before": "Bago",
    "hour": "Oras",
    "image": "Larawan",
    "minute": "Minuto"
  },
  "common": {
    "all": "Lahat",
    "balance": "Balanse",
    "busines": "Baht",
    "cancel": "Kanselahin",
    "confirm": "Kumpirmahin",
    "copper": "Tanso",
    "copy": "Kopyahin",
    "detail": "Detalye",
    "dropDownRefresh": "I-drag pababa upang mag-refresh...",
    "guessTheChampion": "Hulaan ang Kampeon",
    "guessTheWinner": "Hulaan ang Panalo",
    "loading": "Naglo-load...",
    "loadingTwo": "Naglo-load",
    "logOut": "Mag-log Out",
    "resources": "Malaki",
    "faint": "Maliit",
    "odd": "Odd",
    "even": "Even",
    "noFurtherInformation": "Walang Karagdagang Impormasyon",
    "noMore": "Wala Nang Iba Pa...",
    "noMorePosts": "Wala Nang Mga Post",
    "processing": "Proseso",
    "pullDownRefresh": "I-drag pababa upang mag-refresh...",
    "refreshFailed": "Nabigo ang Pag-refresh",
    "related": "Kambal",
    "selectDate": "Pumili ng Petsa",
    "success": "Tagumpay",
    "successfulRenewal": "Matagumpay na Pag-renew",
    "sure": "Tiyak",
    "return": "Bumalik",
    "send": "Ipadala"
  },
  "discounts": {
    "dataDownloadFailed": "Nabigo ang Pag-download ng Data",
    "date": "Petsa",
    "promotion": "Promosyon",
    "seeDetails": "Tingnan ang Mga Detalye"
  },
  upon`,
  },
  "vanCouponList": {
    "empty": "Walang mga kupon",
    "exchange": "Palitan",
    "close": "Isara",
    "enable": "Magagamit",
    "disabled": "Hindi magagamit",
    "placeholder": "Code ng kupon"
  },
  "vanAddressEdit": {
    "area": "Lugar",
    "postal": "Postal",
    "areaEmpty": "Pakipili ang tumatanggap na lugar",
    "addressEmpty": "Hindi pwedeng walang address",
    "postalEmpty": "Maling postal code",
    "defaultAddress": "Itakda bilang default na address",
    "telPlaceholder": "Telepono",
    "namePlaceholder": "Pangalan",
    "areaPlaceholder": "Lugar"
  },
  "vanAddressEditDetail": { "label": "Address", "placeholder": "Address" },
  "vanAddressList": { "add": "Magdagdag ng bagong address" },
  "agreement": {
    "loadingProtocol": "Naglo-load ng protocol...",
    "termsOfUse": "Mga Tuntunin ng Paggamit"
  },
  "bank": {
    "DepositAmount": "Halaga ng Deposito",
    "RechargeAmountDetail": "Kailangan mo ng {time} oras para {busines} {money}. Pakisagot ang seksyon ng deposito",
    "accountHolderName": "Pangalan ng May-ari ng Account:",
    "accountNumber": "Numero ng Account:",
    "actualRechargeAmount": "Aktwal na Halaga ng Deposito",
    "bankCardBound": "Nakataling Bank Card",
    "bankName": "Pangalan ng Bangko:",
    "contactCustomer": "Makipag-ugnayan sa Customer",
    "depositFormSentSuccessfully": "Matagumpay na Pagsusumite ng Deposit",
    "enterYourRealName": "Pakilagay ang iyong tunay na pangalan",
    "failure": "Kabiguan",
    "keepTheSame": "Panatilihin ang Pareho",
    "myBankCard": "Aking Bank Card",
    "noteWhenDepositingMoney": "Tandaan Kapag Nagdedeposito ng Pera",
    "pleaseFillBankName": "Pakilagay ang pangalan ng iyong bangko",
    "pleaseYourAccountNumber": "Pakilagay ang iyong numero ng account",
    "submitDepositRequest": "Isumite ang Aplikasyon ng Deposito",
    "submitOrder": "Isumite ang Order",
    "text": "Tandaan: Mahalaga ang impormasyon ng bank card. Huwag itong baguhin matapos idagdag! Salamat."
  },
  "blindBox": {
    "congratulationsCustomer": "Binabati kita sa customer",
    "drawNow": "Mag-draw Ngayon",
    "hit": "Nanalo ng Premyo",
    "lotteryLaw": "Batas ng Lottery",
    "myScore": "Aking Puntos",
    "noMore": "Wala nang Iba Pa",
    "numberDrawsTimes": "Bilang ng Draws: {gift_times} beses",
    "obtainUserInformation": "Hindi makuha ang impormasyon ng user:",
    "onlineServices": "Online na Serbisyo",
    "requiredPointsPoints": "Kinakailangang Puntos: {point} puntos",
    "rewardPointsRedemptionList": "Listahan ng Pag-redeem ng Puntos na Gantimpala",
    "winningHistory": "Kasaysayan ng Pagkapanalo",
    "winningList": "Nanalo siya ng premyo",
    "winningRecord": "Tala ng Pagkapanalo",
    "withdrawalProfile": "Tala ng Pag-withdraw"
  },
  "chatroom": {
    "before": "Bago",
    "hour": "Oras",
    "image": "Larawan",
    "minute": "Minuto"
  },
  "common": {
    "all": "Lahat",
    "balance": "Balanse",
    "busines": "Baht",
    "cancel": "Kanselahin",
    "confirm": "Kumpirmahin",
    "copper": "Tanso",
    "copy": "Kopyahin",
    "detail": "Detalye",
    "dropDownRefresh": "I-drag pababa upang mag-refresh...",
    "guessTheChampion": "Hulaan ang Kampeon",
    "guessTheWinner": "Hulaan ang Panalo",
    "loading": "Naglo-load...",
    "loadingTwo": "Naglo-load",
    "logOut": "Mag-log Out",
    "resources": "Malaki",
    "faint": "Maliit",
    "odd": "Odd",
    "even": "Even",
    "noFurtherInformation": "Walang Karagdagang Impormasyon",
    "noMore": "Wala Nang Iba Pa...",
    "noMorePosts": "Wala Nang Mga Post",
    "processing": "Proseso",
    "pullDownRefresh": "I-drag pababa upang mag-refresh...",
    "refreshFailed": "Nabigo ang Pag-refresh",
    "related": "Kambal",
    "selectDate": "Pumili ng Petsa",
    "success": "Tagumpay",
    "successfulRenewal": "Matagumpay na Pag-renew",
    "sure": "Tiyak",
    "return": "Bumalik",
    "send": "Ipadala"
  },
  "discounts": {
    "dataDownloadFailed": "Nabigo ang Pag-download ng Data",
    "date": "Petsa",
    "promotion": "Promosyon",
    "seeDetails": "Tingnan ang Mga Detalye"
  },
  "discover": {
    "anErrorOccurredAccess": "Nagkaroon ng error sa pahinang binisita mo! (404)",
    "announce": "Inanunsyo",
    "comment": "Komento",
    "commentList": "Listahan ng Komento",
    "post": "I-post",
    "shareYourComments": "Ibahagi ang iyong mga komento",
    "totalComments": "Kabuuang Komento",
    "wantToComment": "Gustong Magkomento"
  },
  "game": {
    "balanceSheet": "Talaan ng Balanse",
    "game": "Laro",
    "goIntoGame": "Sumabak sa laro",
    "moveAway": "Lumayo",
    "promotionSuccessful": "Matagumpay na Promosyon",
    "total": "Kabuuan",
    "transferToAccount": "I-transfer pabalik sa Account",
    "transferToAccountTwo": "I-transfer sa Account"
  },
  "ganme": {
    "transferGameBalanceAccount": "I-transfer ang Balanse ng Laro sa Account"
  },
  "home": {
    "LocationVsTen": "Isa laban sa sampu",
    "Promotion": "Promosyon",
    "aLittleBit": "Kaunti lang!",
    "aboutToStart": "Malapit nang magsimula",
    "account": "Account",
    "accountBalance": "Balanse ng account",
    "advice": "Payo",
    "agentSetup": "Setup ng ahente",
    "all": "Lahat",
    "amountIsIncorrect": "Mali ang halaga",
    "anOption": "Isang opsyon",
    "babyRabbit": "Batang kuneho",
    "betAmount": "Halaga ng pusta",
    "betNow": "Pumusta na",
    "betNumber": "Numero ng pusta",
    "bettingHistory": "Kasaysayan ng pustahan",
    "bigPair": "Malaking pares",
    "bunnyChooseNumbersNote": "Ang batang kuneho ay pumili ng 8 numero upang gumawa ng tala",
    "calculateCommission": "Paano kalkulahin ang komisyon",
    "cancel": "Kanselahin",
    "cannotGetParameter": "Hindi makuha ang parameter",
    "champion": "Kampiyon",
    "chat": "Chat",
    "chicken": "Manok",
    "chooseMoreNumbersFrom": "Pumili ng 3 o higit pang mga numero mula 1-6",
    "chooseOrMoreNumbers": "Pumili ng 2 o higit pang mga numero mula 1-6",
    "comeBack": "Bumalik",
    "consider": "Isaalang-alang",
    "copper": "Tanso",
    "copyLink": "Kopyahin ang link",
    "cow": "Baka",
    "currentlyNoInformationAvailable": "Hulaan ang mananalo",
    "customerService": "Serbisyo ng kustomer",
    "dealWithTheSituation": "Paano harapin ang sitwasyon",
    "dealerCenter": "Sentro ng dealer",
    "deposit": "Deposito",
    "detail": "Detalye",
    "dog": "Aso",
    "downloadTheApp": "I-download ang aplikasyon",
    "dozens": "Dosena",
    "dragon": "Dragon",
    "eachmaximumDigits": "Ang bawat grupo ng numero ay limitado sa {maxNum} na digit!",
    "enterLeastCharacters": "Maglagay ng hindi bababa sa 5 karakter",
    "enticeToRenew": "Pakitang muli...",
    "forPurposeKeepingPerson": "Libu-libong lasa kumpara sa libo",
    "forgotPassword": "Nakalimutan ang password?",
    "generalElection": "Pangkalahatang halalan",
    "goIntoGame": "Pumunta sa laro",
    "green": "Berde",
    "groupOfSix": "Grupo ng anim",
    "guessTheWinner": "Hulaan ang mananalo",
    "hello": "Kanselahin",
    "home": "Tahanan",
    "homeText": "Ang function na ito ay hindi available sa web version, pakipunta sa app version upang i-download",
    "horse": "Kabayo",
    "hundredFlavors": "Daang lasa",
    "hundredsOfPlaces": "Daan-daan sa isang lugar",
    "i": "sa akin",
    "imbalance": "Hindi balanse!",
    "inFutureYourself": "Libu-libong lasa kumpara sa daan-daan",
    "inThisCaseYou": "Libu-libong lasa kumpara sa sampu-sampu",
    "incorrectText": "Maling login, hindi available ang function na ito, pakikontak ang customer service. Hindi ka pa nakarehistro, magsimula na tayo",
    "informationWhenNotified": "Pindutin para sa karagdagang impormasyon kapag naabisuhan",
    "internetConnection": "Koneksyon sa internet",
    "keepLearnMore": "Sampung lasa kumpara sa kakaibang daan-daan",
    "keepWatchIt": "Sampu laban sa libo-libo",
    "keepYouWatchIt": "Sampu-sampu laban sa sampu",
    "keepYourKeepYourPeeled": "Daang lasa kumpara sa libo-libo",
    "largeOrder": "Malaking order",
    "lightBlue": "Berde ng ilaw",
    "locationVsHundred": "Lokasyon laban sa daan-daan",
    "locationVsThousands": "Isang lokasyon laban sa libo-libo",
    "lottery": "Manalo sa lottery",
    "lotteryPleaseWait": "Lottery sa pag-usad, pakihintay...",
    "lotteryProgress": "Pag-usad ng lottery",
    "lotteryText": "Pagbubunot {expect}, naghihintay",
    "makeYourBalance": "Daan-daan sa sampu",
    "maxSelctNum": "Hanggang 7 bola",
    "maxTenSelctNum": "Maaaring pumili ng hanggang 10 bola",
    "maximumSelectionLimitNumbers": "Pinakamataas na limitasyon ng pagpili ng bawat grupo ng mga numero",
    "maximumWinAmount": "Pinakamataas na halaga ng panalo",
    "member": "Miyembro",
    "monkey": "Unggoy",
    "mouse": "Daga",
    "newspaper": "Dyaryo",
    "note": "Tala",
    "noteTwo": "Tala",
    "number": "Numero",
    "numberInvitations": "Bilang ng mga imbitasyon",
    "numbersFromBetTwoTables": "Pumili ng 2 numero mula 0-9 upang tumaya sa dalawang pinto",
    "oddSelection": "Pagpili ng kakaiba",
    "odds": "Tsansa",
    "oddsTwo": "Napag-uusapan na presyo",
    "openNextPeriod": "Buksan ang susunod na panahon",
    "otherNumber": "Ibang numero",
    "panda": "Panda",
    "pig": "Baboy",
    "placeABet": "Tumaya",
    "placeBet": "Tumaya",
    "placesVsTenThousand": "Isang lugar laban sa sampu-sampu",
    "playStyle": "Estilo ng paglalaro",
    "pleaseEnterAmount": "Pakilagay ang halaga",
    "pleaseEnterCorrectBetAmount": "Pakilagay ang tamang halaga ng taya",
    "pleaseEnterTheAmount": "Pakilagay ang tamang halaga ng taya",
    "pleaseLoginFirst": "Pakilagay muna ang iyong login!",
    "pleaseSelectTheTeam": "Pakipiliin ang koponan na gusto mong tayaan",
    "prize10": "Premyo 10",
    "prize3": "Premyo 3",
    "prize4": "Premyo 4",
    "prize5": "Premyo 5",
    "prize6": "Premyo 6",
    "prize7": "Premyo 7",
    "prize8": "Premyo 8",
    "prize9": "Premyo 9",
    "probabilityWinning": "Tsansa ng panalo",
    "purple": "Lila",
    "quantityAvailable": "Magagamit na dami (VND)",
    "rabbit": "Kuneho",
    "red": "Pula",
    "registerAsAnAgent": "Magrehistro bilang ahente",
    "registerText": "Pagkatapos maging ahente, upang mapadali ang pag-promote ng iyong ahente, pakipunta sa [Referral link] seksyon upang kopyahin at ibahagi sa iyong mga kaibigan o kliyente, upang ma-download at magrehistro sila sa pamamagitan ng iyong ibinahaging link at maging miyembro sa iyong linya",
    "releaseToRefresh": "Ipakawalan upang i-refresh...",
    "reputationText": "Kailangan mong magdeposito ng {minamount} coins bago mo mabuksan ang larong ito",
    "runnerUp": "Pangalawa",
    "sameNumber": "Parehong problema",
    "selectNumbersCreateNote": "Pumili ng 3 numero upang gumawa ng tala",
    "selectNumbersCreateNote2": "Pumili ng 2 numero upang gumawa ng tala",
    "selectNumbersCreateNote4": "Pumili ng 4 na numero upang gumawa ng tala",
    "selectNumbersCreateNote5": "Pumili ng 5 numero upang gumawa ng tala",
    "selectNumbersCreateNote6": "Pumili ng 6 na numero upang gumawa ng tala",
    "selectNumbersCreateNote7": "Pumili ng 7 numero upang gumawa ng tala",
    "selectNumbersToCreateNote2": "Pumili ng 2 numero upang gumawa ng tala",
    "send": "Ipadala",
    "serialNumber": "Serial na numero",
    "setBet": "Itakda ang pusta",
    "share": "Ibahagi",
    "sharelinkFriendsShare": "Ibinahaging link na kinopya, pakibahagi",
    "sheep": "Tupa",
    "singleAmount": "Solong halaga ng pusta",
    "singleBonus": "Solong bonus",
    "singleInvestingYuan": "Tukoy na bonus = bonus na matatanggap mo kung mamumuhunan ka ng 2 yuan",
    "smallDouble": "Maliit na doble",
    "snake": "Ahas",
    "specialCode": "Espesyal na code:",
    "startBetting": "Simulan ang pustahan",
    "successfulAgentApplication": "Matagumpay na aplikasyon bilang ahente",
    "successfullyRenewed": "Matagumpay na na-renew...",
    "takeCareYourLife": "Daang lasa laban sa sampu-sampu",
    "team": "Koponan",
    "teamsParticularOrder": "Mga koponan (hindi sa partikular na pagkakasunod-sunod)",
    "tenThousandVsPlaces": "Sampu-sampu laban sa isang lugar",
    "tenVsPlaces": "Sampu laban sa isang lugar",
    "tensThousands": "Sampu-sampu",
    "text1": "Mali ang impormasyong nai-post, pakilag out at mag-log in muli",
    "theFutureMore": "Libu-libong lasa kumpara sa sampung lasa",
    "theInformationMoment": "Hulaan ang mananalo, kasalukuyang walang impormasyon",
    "theLastThree": "Huling tatlo",
    "thePersonIs": "Libu-libong lasa",
    "theTotalAmountIs": "Ang kabuuang halaga ay",
    "thereCurrentlyInformationAvailable": "Kasalukuyang walang impormasyon",
    "thirdGroup": "Pangatlong grupo",
    "thousandVsPlaces": "Libo-libo laban sa isang lugar",
    "threeDifferentNumbers": "Tatlong magkaibang numero",
    "threeTimesRow": "Tatlong beses na magkakasunod",
    "tiger": "Tigre",
    "todayPrize": "—— Panalo ngayon ——",
    "token": "Token",
    "top3": "Nangungunang 3",
    "total": "Kabuuan",
    "totalTwo": "Kabuuan",
    "transferSuccessful": "Matagumpay na nailipat!",
    "treasureChest": "Kahon ng kayamanan",
    "twoDifferentNumbers": "Dalawang magkaibang numero",
    "typingPleaseContactService": "Nagta-type, pakikontak ang customer service",
    "unableCopyShareLink": "Hindi makopya ang ibinahaging link",
    "unableGetParameters": "Hindi makuha ang mga parameter",
    "unableToParameters": "Hindi makuha ang mga parameter",
    "underOrder": "Nasa ilalim ng order",
    "unit": "Yunit",
    "userSendMessage": "Nagpadala ng mensahe ang gumagamit",
    "waiting": "Naghihintay",
    "waitingForLottery": "Naghihintay sa lottery",
    "winnersList": "—— Listahan ng mga nanalo ——",
    "withdrawCommission": "I-withdraw ang komisyon",
    "withdrawMoney": "Mag-withdraw ng pera",
    "withdrawalCommissionAmount": "Pakilagay ang halaga ng komisyon para i-withdraw",
    "withdrawalProfile": "Rekord ng pag-withdraw",
    "yourLoginInformationInvalid": "Mali ang iyong impormasyon sa pag-login. Pakilag-in muli",
    "yourReferralLink": "Iyong referral na link",
    "chatRoom": "Silid-chat",
    "today": "Ngayon",
    "openPrize": "Buksan ang premyo",
    "availableBalance": "Magagamit na balanse",
    "betAmountCannotBeUnderThan": "Ang halaga ng pusta ay hindi dapat bababa sa {busines} 10!",
    "betNowTwo": "Pumusta na",
    "chooseGreen": "Pumili ng berde",
    "choosePurple": "Pumili ng lila",
    "choosePurpleTwo": "Pumili ng lila",
    "chooseRed": "Pumili ng pula",
    "drawingPleaseWait": "Pagbubunot, pakihintay...",
    "goToStart": "Pumunta sa simula",
    "iAgree": "Sumasang-ayon ako",
    "nextOpening": "Susunod na pagbubukas",
    "openPeriod": "Panahon ng pagbubukas",
    "openingNumber": "Numero ng pagbubukas",
    "optionalAmount": "Opsyonal na halaga ({busines})",
    "outOfBalance": "Wala nang balanse!",
    "pleaseEnterAmountTwo": "Pakilagay ang halaga ({busines})",
    "pleaseEnterValidBetAmount": "Pakilagay ang wastong halaga ng pusta",
    "pleaseSelectNumber": "Pakipili ang numerong gusto mong tayaan",
    "release": "Pinalaya",
    "result": "Resulta",
    "rulesGame": "Mga patakaran sa laro",
    "selectNumber": "Pumili ng numero",
    "tailNumber": "Numero sa buntot",
    "theNumberStagesChanged": "Ang yugto ng numero ay nagbago. Ngayon {expect}",
    "theTotalContractAmountIs": "Ang kabuuang halaga ng kontrata ay",
    "time": "Oras",
    "waitingForOpening": "Naghihintay sa pagbubukas!",
    "yourIdentityInvalid": "Hindi wasto ang iyong pagkakakilanlan. Pakilag-in muli!",
    "openPeriodTailNumber": "Numero sa buntot ng panahon ng pagbubukas",
    "myBet": "Aking taya",
    "listNo": "Manalo ng malaking premyo!",
    "agentVerification": "Pagpapatunay ng ahente"
  },
  "login": {
    "APPOnlySupportsAndroidUsersDownload": "Ang app ay sumusuporta lamang sa mga Android user para sa pag-download!",
    "LogOut": "Mag-log out",
    "Register an account": "Magrehistro ng account",
    "accountBalance": "Balanse ng account",
    "bankInformationLink": "I-link ang impormasyon ng bangko",
    "changeLoginPassword": "Baguhin ang password sa pag-login",
    "confirmPassword": "Kumpirmahin ang password",
    "customerCare": "Serbisyong pang-kustomer",
    "customerService": "Serbisyo ng kustomer!",
    "customerServiceTow": "Serbisyo ng kustomer",
    "deposit": "Deposito",
    "doYouWantToLogOut": "Gusto mo bang mag-log out?",
    "downloadTheApp": "I-download ang app",
    "enterYourPhoneNumber": "Ilagay ang iyong numero ng telepono",
    "forgotPassword": "Nakalimutan ang password",
    "gameBalance": "Balanse sa laro",
    "history": "Kasaysayan",
    "historyChange": "Mga pagbabago sa kasaysayan",
    "incorrectAccountPassword": "Mali ang account o password",
    "information": "Impormasyon",
    "logIn": "Mag-log in",
    "login": "Mag-log in",
    "loginPassword": "Password sa pag-login",
    "loginSuccessful": "Matagumpay na pag-login",
    "lotteryBettingHistory": "Kasaysayan ng pustahan sa lottery",
    "lotteryError": "Error sa lottery",
    "memberLogin": "Pag-login ng miyembro",
    "myBankCard": "Aking bank card",
    "password": "Password",
    "phoneNumber": "Numero ng telepono",
    "pleaseEnterPassword": "Pakilagay ang iyong password",
    "pleaseEnterPhoneNumber": "Pakilagay ang iyong numero ng telepono!",
    "pleaseEnterPhoneNumberOne": "Pakilagay ang wastong numero ng telepono",
    "pleaseEnterReferralCode": "Pakilagay ang referral code",
    "pleaseLogin": "Pakimag-log in",
    "pleaseReEnterPassword": "Pakilagay muli ang iyong password",
    "referralCode": "Referral code",
    "registerAccount": "Magrehistro ng account",
    "rememberPassword": "Tandaan ang password",
    "theWebVersionDoesNotThisFunctionVersion": "Ang web version ay walang ganitong function. Pakipunta sa app version para sa pag-download",
    "token": "Token",
    "underConstructionPleaseStayTuned": "Kasulukuyang ginagawa. Pakiaabangan",
    "withdrawMoney": "Mag-withdraw ng pera"
  },
  "lottery": {
    "betNow": "Tumaya na",
    "comingSoon": "Darating na",
    "lottery": "Bilang ng mga bunutan",
    "lotteryForum": "Forum ng lottery",
    "lotteryText": "Ang web version ay walang ganitong function. Pakipunta sa app version para sa pag-download",
    "notification": "Abiso",
    "session": "Buksan ang sesyon",
    "time": "Oras"
  },
  "my": {
    "changePassword": "Baguhin ang password",
    "complete": "Kumpleto",
    "income": "Kita",
    "isAccess": "Ina-access...",
    "newPassword": "Bagong password",
    "notEditable": "Hindi puwedeng i-edit",
    "oldPassword": "Lumang password",
    "onlySendOnceEverySeconds": "Matagumpay na naipadala. Maaaring ipadala lamang isang beses bawat 60 segundo",
    "pagingPageSize": "Kabuuang rekord {total}, bawat pahina ay nagpapakita ng {pageSize}, kabuuan ng {num} pahina",
    "passwordBeenModified": "Ang password sa pagbabayad ay na-modify na",
    "pendingReview": "Naghihintay sa pagsusuri",
    "pleaseEnterDigitsLoginPassword": "Pakilagay ang iyong 6-digit na password sa pag-login",
    "pleaseLeastDigits": "Pakilagay ng hindi bababa sa 6 na digit",
    "pleaseMobilePhoneNumber": "Pakilagay ang wastong numero ng mobile phone",
    "pleaseTryAgainLater": "Pakisubukan muli mamaya",
    "receiveVerificationCode": "Tumanggap ng verification code sa pamamagitan ng SMS",
    "spending": "Gastos",
    "transactionDetails": "Mga detalye ng transaksyon",
    "wasSuccessfullyReceived": "Matagumpay na natanggap ang CAPTCHA"
  },
  "recharge": {
    "amount": "Halaga",
    "applicationNumber": "Numero ng aplikasyon",
    "complete": "Kumpleto",
    "depositHistory": "Kasaysayan ng deposito",
    "failure": "Pagkabigo",
    "membershipAccount": "Account ng miyembro",
    "noDataAvailable": "Walang magagamit na data",
    "pending": "Nakabinbin",
    "pleaseMembershipAccount": "Pakilagay ang iyong account ng miyembro",
    "pleaseSelectDepositMethod": "Pakipili ng paraan ng deposito",
    "realization": "Aktwal na natanggap",
    "selectItem": "Pumili ng item",
    "success": "Tagumpay",
    "thereCurrentlyRecords": "Walang kasalukuyang mga rekord",
    "thereHistoryCurrently": "Walang kasalukuyang kasaysayan",
    "thereNoSuchMethod": "Walang ganitong paraan ng deposito. Pakipili ng ibang paraan"
  },
  "registe": {
    "backToLogin": "Bumalik sa pag-login",
    "congratulationsYourRegistrationSuccessful": "Binabati kita, matagumpay ang iyong pagpaparehistro",
    "getBackSecond": "Kunintin muli ang pangalawang SS",
    "iAccept": "Tinatanggap ko",
    "loginSuccessful": "Matagumpay na pag-login",
    "registeredMember": "Rehistradong miyembro",
    "sentSuccessfullyCanOnly": "Matagumpay na naipadala. Maaaring ipadala lamang isang beses bawat 60 segundo",
    "submitRegistration": "Ipasa ang pagpaparehistro",
    "twoInconsistentPasswordEntries": "Dalawang hindi magkatugmang password!",
    "userAgreementAndPrivacyPolicy": "Kasunduan ng gumagamit at patakaran sa privacy",
    "verificationCodeReceived": "Natanggap ang verification code",
    "youMustAgree": "Kailangan mong sumang-ayon",
    "codeToast": "Kinukuha ang verification code"
  },
  "share": {
    "the": "Ang"
  },
  "single": {
    "all": "Lahat",
    "betAmount": "Halaga ng taya",
    "lotteryBettingHistory": "Kasaysayan ng pustahan sa lottery",
    "notOpenedYet": "Hindi pa binuksan ang premyo",
    "profit": "Kita",
    "settled": "Naayos na",
    "status": "Katayuan",
    "time": "Oras"
  },
  "withdraw": {
    "SMSCodeConfirmation": "Kumpirmahin ang SMS code",
    "advice": "Payo",
    "amountAvailableWithdrawal": "Halagang magagamit para sa withdrawal",
    "cardTailNumber": "Huling numero ng card",
    "currentlyWithdrawalHistory": "Walang kasalukuyang kasaysayan ng withdrawal",
    "failedChangePassword": "Nabigong palitan ang password",
    "getCode": "Kunin ang code",
    "getConfirmationCode": "Kunin ang kumpirmasyon code",
    "isAccessTo": "Ina-access...",
    "keyPassword": "Bagong unlock password",
    "lastNumberMumber": "Huling numero",
    "orderID": "Order ID",
    "paymentPasswordModifiedSuccessfully": "Matagumpay na nabago ang password sa pagbabayad",
    "paymentUnlockPassword": "I-unlock ang password sa pagbabayad",
    "phoneNumberCodeReceived": "Natanggap ang code ng numero ng telepono",
    "pleaseDigitsPaymentPassword": "Pakilagay ang 4-digit na unlock password para sa pagbabayad",
    "pleaseEnterPassword": "Pakilagay ang iyong password",
    "pleaseEnterWithdrawalAmount": "Pakilagay ang halaga ng withdrawal",
    "pleaseMobilePhoneNumber": "Pakilagay ang wastong numero ng mobile phone",
    "pleaseTryAgainLater": "Pakisubukan muli mamaya",
    "pleaseVerificationCode": "Pakilagay ang SMS verification code",
    "readingComprehension": "Pag-unawa sa pagbasa...",
    "sent": "Naipadala",
    "submitInformation": "Ipasa ang impormasyon",
    "theObtainedSuccessfully": "Matagumpay na nakuha ang CAPTCHA",
    "tipsText": "Wala ka pang naitakdang bank card. Gusto mo bang itakda ito?",
    "tipsUserText": "Wala ka pang naitakdang unlock password para sa pagbabayad. Gusto mo bang pumunta sa mga setting?",
    "toastMoneyCan": "Kinakailangan ang dumadaloy na tubig",
    "tutorReason": "Dahilan ng tutor",
    "underConsideration": "Isinasaalang-alang",
    "underReview": "Nasa pagsusuri",
    "withdrawAll": "I-withdraw lahat",
    "withdrawOrContinueConsume": "I-withdraw o ipagpatuloy ang pagkonsumo"
  },
  "method": {
    "bs": "Alon ng kulay",
    "bz": "Leopard",
    "dwd": "Posisyon",
    "dxds": "Malaki maliit, odd even",
    "ebth": "Dalawang magkaibang numero",
    "ebth-bz-bz": "Dalawang magkaibang numero",
    "eqz": "Tumama ng dalawang beses",
    "eth": "Dalawang magkatulad na numero",
    "eth-dx-dx": "Dalawang magkatulad na numero - single choice",
    "eth-fx-fx": "Dalawang magkatulad na numero - check",
    "gyh": "Guan Yahe",
    "hedxds": "Huling dalawang laki odd at even",
    "hezhix": "Direktang pagpili ng huling dalawang item",
    "hezux": "Huling dalawang pagpipilian",
    "hl": "Pula at berde",
    "hl-dwd": "Posisyon ng pantog na pula at berde",
    "hl-sb": "Pula berde",
    "hl-sb-z": "Lila",
    "hl-sb-zo": "Lila",
    "hszhix": "Direktang pagpili mula sa huling tatlong item",
    "hszl": "Huling tatlong grupo, anim na grupo",
    "hszs": "Huling tatlong grupo, tatlong grupo",
    "hszux": "Huling tatlong grupo, opsyonal",
    "hz": "Kabuuan",
    "hz-hz-10": "10",
    "hz-hz-11": "11",
    "hz-hz-12": "12",
    "hz-hz-13": "13",
    "hz-hz-14": "14",
    "hz-hz-15": "15",
    "hz-hz-16": "16",
    "hz-hz-17": "17",
    "hz-hz-18": "18",
    "hz-hz-3": "3",
    "hz-hz-4": "4",
    "hz-hz-5": "5",
    "hz-hz-6": "6",
    "hz-hz-7": "7",
    "hz-hz-8": "8",
    "hz-hz-9": "9",
    "hz-hz-dxds": "Kabuuan odd o even",
    "lh": "Tigre at dragon",
    "lhao": "Serial number",
    "lhao-slh-slh": "Tatlong magkakasunod na numero",
    "qedxds": "Unang dalawang laki odd at even",
    "qezhix": "Direktang pagpili ng unang dalawang posisyon",
    "qezux": "Dalawang sikat na pagpipilian",
    "qszhix": "Direktang pagpili ng unang tatlong posisyon",
    "qszl": "Unang tatlong grupo, anim na grupo",
    "qszs": "Unang tatlong grupo, tatlong grupo",
    "qszux": "Tatlong unang posisyon",
    "rx1z1": "Pumili ng isa",
    "rx2z2": "Pumili ng dalawa mula sa dalawa",
    "rx3z3": "Pumili ng tatlo mula sa tatlo",
    "rx4z4": "Pumili ng apat mula sa kahit anong apat",
    "rx5z5": "Pumili ng lima mula sa lima",
    "rx6z5": "Pumili ng lima mula sa anim",
    "rx7z5": "Pumili ng lima mula sa pito",
    "rx8z5": "Pumili ng lima mula sa walo",
    "sbth": "Tatlong magkaibang numero",
    "sbth-bz-bz": "Tatlong magkaibang numero",
    "sqz": "Tatlong buong puntos",
    "sth": "San Dong",
    "sth-dx-dx": "Tatlong magkatulad na numero - single choice",
    "sth-tx-tx": "Tatlong karaniwang magkatulad na numero",
    "tm": "Special code",
    "tmdxds": "Special code single size at even",
    "tmdxdsfh": "Special code odd at even mixed size",
    "tmhsdxds": "Special code odd at even mixed size",
    "tmsb": "Special wave color code",
    "tmwsdx": "Special code mantissa",
    "tx": "General election",
    "zh": "Kabuuan",
    "zm": "Positive code",
    "zx": "Zhengxiao",
    "zxzl": "Anim na grupo pagpili",
    "zxzs": "Tatlong grupo pagpili"
  },
  "chat_room": {
    "bet": "Pusta",
    "getPrize": "Manalo ng malaking premyo!"
  },
  "languageSett": "Setting ng wika"
}
